import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { Container, Contacts, PageOffset } from 'components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Text me a 💌 using the form below.`}</p>
    <Container mdxType="Container">
    <Contacts mdxType="Contacts" />
    </Container>
    <PageOffset mdxType="PageOffset" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      